import React from 'react';
import './Video.css';

const VideoSection = () => {
    return (
        <section id="video-571">
            <div className="cs-container">
                <a 
                    href="https://www.youtube.com/watch?v=5GTx4KW2CMg&t=1937s" 
                    className="cs-big-link" 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    {/* Background Image */}
                    <picture className="cs-background">
                        <source 
                            media="(min-width: 1024px)" 
                            srcSet="https://img.youtube.com/vi/5GTx4KW2CMg/maxresdefault.jpg" 
                        />
                        <source 
                            media="(min-width: 601px)" 
                            srcSet="https://img.youtube.com/vi/5GTx4KW2CMg/hqdefault.jpg" 
                        />
                        <source 
                            media="(max-width: 600px)" 
                            srcSet="https://img.youtube.com/vi/5GTx4KW2CMg/sddefault.jpg" 
                        />
                        <img 
                            aria-hidden="true" 
                            loading="lazy" 
                            decoding="async" 
                            alt="YouTube Thumbnail" 
                            width="1280" 
                            height="580" 
                        />
                    </picture>
                    {/* Play Icon */}
                    <img 
                        className="cs-link-icon" 
                        aria-hidden="true" 
                        loading="lazy" 
                        decoding="async" 
                        src="https://csimg.nyc3.cdn.digitaloceanspaces.com/videos/play2.svg" 
                        alt="play icon" 
                        width="86" 
                        height="94" 
                    />
                </a>
                <div className="cs-content">
                    <h2 className="cs-video-title">
                        A Vision For Our Commmunity.
                    </h2>
                    <p className="cs-text">
                    As fellow PeterOneFive writer Theo Howard highlights, the Pascua Project is poised for success and serves as the ideal model to emulate.                    </p>
                </div>
            </div>
        </section>
    );
};

export default VideoSection;
