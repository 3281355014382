import React, { useState } from "react";
import './ResidentInquiry.css';
const  ResidentInquiry = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const faqs = [
    {
      question: "Can you briefly tell us a little bit about yourself?",
      answer:
        "Provide an overview of your background, current life situation, and major interests or goals, tailored to the context of this project.",
    },
    {
      question: " What in particular about the vision for Pascua are you personally drawn to the most? ",
      answer:
        "Express your genuine alignment with Pascua's mission, highlighting specific elements (e.g., community, tradition, etc...) that resonate with you personally.",
    },
    {
      question: "Why do you want to be involved with and work for the founding of Pascua? ",
      answer:
        "Explain your motivation for contributing to Pascua, focusing on how the vision aligns with your values, skills, or life aspirations.",
    },

    {
        question: "What knowledge, skillsets, experience, interests, professional and social relationships, etc. do you have that can help add value to the project?",
        answer:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit convallis nunc neque, bibendum pulvinar vitae commodo velit. Proin diam tortor sed malesuada nunc, habitant. Dignissim ipsum porta enim, magna urna, quam.",
      },

      {
        question: "Can you name a few of your personal strengths and weaknesses? ",
        answer:
          "Be honest and balanced; highlight strengths that will benefit the project and weaknesses that you are actively working on improving.",
      },

      {
        question: "What are some of your favorite hobbies and pastimes?",
        answer:
          "Mention hobbies that reflect your personality.",
      },

      {
        question: "Where did you grow up and where do you currently live? ",
        answer:
          "Provide a brief summary of your geographical background and current location, emphasizing any experiences that shaped your interest in community life.",
      },

      {
        question: " What has been your experience with regard to close-knit family or community life? How would you describe your role in it? ",
        answer:
          "Share insights into your involvement in family or community settings, highlighting your contributions and the value you place on close connections.",
      },

      {
        question: "What is your attitude towards the traditional latin mass?",
        answer:
          "Answer sincerely, describing your appreciation for or interest in the traditional Latin Mass and how it fits into your life.",
      },

      {
        question: "How much land (if any) would you ideally like to own and what would its intended use be?",
        answer:
          "Specify your preferences for land ownership, detailing practical or personal uses (e.g., gardening, farming, space for family.",
      },

      {
        question: "What would you consider your budget for a home to be?",
        answer:
          "Provide a realistic estimate that aligns with your financial situation and vision for the lifestyle you hope to achieve in Pascua.",
      },

      
    
  ];

  return (
    <section id="faq-1263">
      <div className="cs-container">
        <div className="cs-content">
          <span className="cs-topper">Join The Community</span>
          <h2 className="cs-title">Resident Inquiry</h2>
        </div>
        <ul className="cs-faq-group">
          {faqs.map((faq, index) => (
            <li
              key={index}
              className={`cs-faq-item ${activeIndex === index ? "active" : ""}`}
            >
              <button
                className="cs-button"
                onClick={() => toggleFAQ(index)}
              >
                <span className="cs-button-text">{faq.question}</span>
              </button>
              {activeIndex === index && <p className="cs-item-p">{faq.answer}</p>}
              
            </li>
          ))}
        </ul>
        <a href="https://wormhole.app/" className="cs-button-solid">Upload Video</a>
        <a href="mailto:pascuaflorida2022@gmail.com" className="cs-button-solid">Email Reponses</a>
      </div>
    </section>
  );
};

export default ResidentInquiry;
