import React from 'react';
import './Donate.css';
import bottomhero from '../images/developmentplan-8.png'

const Donate = () => {
  return (
    <section id="subscribe-1721">
      <div className="cs-container">
        <div className="cs-content">
          <span className="cs-topper">Donate</span>
          <h2 className="cs-title">Become A Pascua Patron & Help Us Build Amidst The Ruins</h2>
          <p className="cs-text">
          If you’d like to join us in our effort to build an outpost of Christendom amidst our civilizational ruins please consider making a donation. No gift is too small or too large. If you feel passionately about our mission and would like to do more consider becoming a Patron by signing up for regular recurring donations or ask us about how you can become a longtime Partner by contacting us at pascuaflorida2022@gmail.com</p>
<p className="cs-text">
Pascua Community Land Trust, Inc., is a nonprofit organization that is currently filing for tax exempt status.
</p>

<a href="https://www.zeffy.com/en-US/donation-form/f320fc86-b84e-4348-977f-0fcc4a55764c" className="cs-button-solid cs-nav-button">
          Donate
        </a>
          {/* Graphic */}
          <img
            className="cs-floater"
            loading="lazy"
            decoding="async"
            src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Graphics/white-spiral.svg"
            alt="graphic"
            width="1920"
            height="486"
            aria-hidden="true"
          />
        </div>
      </div>
      {/* Background Image */}
      <picture className="cs-background">
        <source
          media="(max-width: 600px)"
          srcSet={bottomhero}
        />
        <source
          media="(min-width: 601px)"
          srcSet={bottomhero}
        />
        <img
          aria-hidden="true"
          loading="lazy"
          decoding="async"
          src={bottomhero}
          alt="meeting"
          width="360"
          height="316"
        />
      </picture>
    </section>
  );
};

export default Donate;
