import { faBookBible, faChurch, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import AboutUs from './AboutUs';
import ArticleSection from './Articles';
import DevelopmentPlan from './DevelopmentPlan';
import Footer from './Footer';
import './LandingPage.css';
import OurMission from './OurMission';
import ResidentInquiry from './ResidentInquiry';
import SubscribeEmail from './SubscribeEmail';
import VideoSection from './VideoSection';
import WhereWeStand from './WhereWeStand';
import JoshContent from './JoshContent';
import PhillipContent from './PhillipContent';

function LandingPage() {

  return (
    <div className="landing-page">

      {/* New Hero Section with Asturias Image */}
      <section id="hero-1946" className="hero-section">
        <div className="cs-container">
          <div className="cs-content">
            <h2 className="cs-title">A Place Where Memory And Tradition Are Alive</h2>
            <div className="cs-flex">
              <p className="cs-text">
                 Where Traditional Urbanism, Agrarianism, Distributism, The Liberal Arts, Historical Rootedness & The Liturgical Year Meet To Foster An Authentic Catholic Culture.
              </p>
              <a href="https://drive.google.com/file/d/1WdeqlTZJYAHNFIkm-jKFTMswRMFqJ8Wk/view" className="cs-button-solid">Learn More</a>
            </div>
          </div>

          {/* Replace the HTML image with Asturias Image */}
          <picture className="cs-picture">
            <img
              decoding="async"
              src={require('../images/asturias.jpg')} // Replace with Asturias image
              alt="Asturias"
              className="hero-image-asturias"
            />
          </picture>

          <ul className="cs-card-group">
            {[
              {
                icon: 'tool.svg',
                title: 'Tradition',
                text: 'Where life is lived in continuity with our Christian ancestors.',
              },
              {
                icon: 'hard-hat.svg',
                title: 'Catholic Culture',
                text: 'Pasuca is a place where faithful Catholics can come together and settle.',
              },
              {
                icon: 'double-bed.svg',
                title: 'Community',
                text: 'Where the past is celebrated memory cherised.',
              },
              {
                icon: 'building.svg',
                title: 'Architecture',
                text: 'Pascua will celebrate both Spanish and Southern heritage through architecture blending Spanish and old Florida styles.',
              },
            ].map((item, index) => (
              <li key={index} className="cs-item">
              <picture className="cs-icon-picture">
                {item.title === 'Faith' ? (
                  // Use FontAwesomeIcon for the "Faith" card
                  <FontAwesomeIcon icon={faChurch} className="cs-icon" />

                ) : item.title === 'Community' ? (
                  <FontAwesomeIcon icon={faUsers} className="cs-icon" />

                ) : item.title === 'Catholic Culture' ? (
                  <FontAwesomeIcon icon={faChurch} className="cs-icon" />

                ) : item.title === 'Tradition' ? (
                  <FontAwesomeIcon icon={faBookBible} className="cs-icon" />
                ) : (
                  // Default SVG icon for other cards
                  <img
                    src={`https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Icons/${item.icon}`}
                    decoding="async"
                    alt={`${item.title} icon`}
                    className="cs-icon"
                  />
                )}
                </picture>
                <div className="cs-text-group">
                  <h3 className="cs-h3">{item.title}</h3>
                  <p className="cs-item-text">{item.text}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>

      {/* Rest of the page */}
      <OurMission />
      <DevelopmentPlan />
      <WhereWeStand />
      <AboutUs />
      <JoshContent />
      <PhillipContent />
      <ResidentInquiry />
      <VideoSection />
      <ArticleSection />
      <SubscribeEmail />
    </div>
  );
}

export default LandingPage;
