import React, { useState, useEffect } from 'react';
import './NavBar.css'; // Assuming CSS classes are in a separate file
import logo from '../images/logo1.svg'

const NavBar = () => {
  const [isNavActive, setNavActive] = useState(false);
  const [isAriaExpanded, setAriaExpanded] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY >= 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleNav = () => {
    setNavActive((prev) => !prev);
    setAriaExpanded((prev) => !prev);
  };

  const toggleDropdown = (e) => {
    e.currentTarget.classList.toggle('cs-active');
  };

  return (
    <header id="cs-navigation" className={`${isNavActive ? 'cs-active' : ''} ${hasScrolled ? 'scroll' : ''}`}>
      <a href="/" className="cs-logo" aria-label="back to home">
        <img
          src={logo}
          alt="logo"
          width="210"
          height="29"
          aria-hidden="true"
          decoding="async"
        />
      </a>

      <ul className="cs-top-bar">
        <li className="cs-item">
          <picture className="cs-picture">
            <img
              className="cs-icon"
              src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons%2Fphone-stroke-green.svg"
              alt="icon"
              width="24"
              height="24"
              aria-hidden="true"
              decoding="async"
            />
          </picture>
          <div className="cs-flex">
            <span className="cs-header">Call Us Now</span>
            <a href="tel:555-555-0234" className="cs-link">
              (555) 555-0234
            </a>
          </div>
        </li>
        <li className="cs-item">
          <picture className="cs-picture">
            <img
              className="cs-icon"
              src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons%2Fmail-stroke-green.svg"
              alt="icon"
              width="24"
              height="24"
              aria-hidden="true"
              decoding="async"
            />
          </picture>
          <div className="cs-flex">
            <span className="cs-header">Send A Message</span>
            <a href="mailto:Info@email.com" className="cs-link">
              pascuaflorida2022@gmail.com
            </a>
          </div>
        </li>
        <li className="cs-item cs-remove">
        </li>
      </ul>

      <div className="cs-bottom-bar">
        <nav className="cs-nav" role="navigation">
          <button className="cs-toggle" aria-label="mobile menu toggle" onClick={toggleNav}>
            <div className="cs-box" aria-hidden="true">
              <span className="cs-line cs-line1" aria-hidden="true"></span>
              <span className="cs-line cs-line2" aria-hidden="true"></span>
              <span className="cs-line cs-line3" aria-hidden="true"></span>
            </div>
          </button>

          <div className="cs-ul-wrapper">
            <ul id="cs-expanded" className="cs-ul" aria-expanded={isAriaExpanded}>
              <li className="cs-li">
                <a href="/" className="cs-li-link cs-active">
                  Home
                </a>
              </li>
              <li className="cs-li">
                <a href="/resident-inquiry" className="cs-li-link">
                  Resident Inquiry
                </a>
              </li>
              <li className="cs-li">
                <a href="/Donate" className="cs-li-link">
                  Donate
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <a href="https://www.zeffy.com/en-US/donation-form/f320fc86-b84e-4348-977f-0fcc4a55764c" className="cs-button-solid cs-nav-button">
          Donate
        </a>
      </div>
    </header>
  );
};

export default NavBar;
