import React from 'react';
import article1 from '../images/article1.jpg';
import article2 from '../images/article2.jpg';
import article3 from '../images/article3.jpg';
import './Articles.css'; // Assuming you have a CSS file for styling

const ArticleSection = () => {
    return (
        <section id="blog-1580">
            <div className="cs-container">
                <div className="cs-content">
                    <div className="cs-content-flex">
                        <span className="cs-topper">News And Articles</span>
                        <h2 className="cs-title">Directly From The Latest News and Articles</h2>
                    </div>
                    <a href="https://onepeterfive.com/author/josuehernandez/" className="cs-button-solid">View All</a>
                </div>
                <ul className="cs-card-group">
                    {[
                        {
                            id: 1,
                            imgSrc: article1,
                            date: "12th Sep, 2024",
                            title: "Urban Catholic Tradition",
                            description: "I Set Out To Build A Town.",
                            link: "https://onepeterfive.com/the-urban-catholic-tradition/"
                        },
                        {
                            id: 2,
                            imgSrc: article2,
                            date: "10th June, 202",
                            title: "The Eucharistic Heart Of Jesus & The Love That Begets Christian Culture",
                            description: "The chief reason of this feast is to commemorate the love of Our Lord Jesus Christ in the mystery of the Eucharist.",
                            link: "https://onepeterfive.com/the-eucharistic-heart-of-jesus-the-love-that-begets-christian-c" // Replace with actual article URL
                        },
                        {
                            id: 3,
                            imgSrc: article3,
                            date: "18th Apr, 2024",
                            title: "Christ the King vs. American Conservatism",
                            description: "12 Reasons Why Classical Liberalism Cannot Tolerate Christian Truth",
                            link: "https://onepeterfive.com/christ-the-king-vs-american-conservatism/" // Replace with actual article URL
                        }
                    ].map(({ id, imgSrc, date, title, description, link }) => (
                        <li className="cs-item" key={id}>
                            <a href={link} className="cs-link" target="_blank" rel="noopener noreferrer">
                                <picture className="cs-picture" aria-hidden="true">
                                    <source
                                        media="(max-width: 600px)"
                                        srcSet={imgSrc}
                                    />
                                    <source
                                        media="(min-width: 601px)"
                                        srcSet={imgSrc}
                                    />
                                    <img
                                        loading="lazy"
                                        decoding="async"
                                        src={imgSrc}
                                        alt={title}
                                        width="413"
                                        height="284"
                                    />
                                </picture>
                                <div className="cs-flex">
                                    <span className="cs-date">{date}</span>
                                    <h3 className="cs-h3">{title}</h3>
                                    <p className="cs-item-text">{description}</p>
                                </div>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default ArticleSection;
