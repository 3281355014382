import React from "react";
import "./SubscribeEmail.css"; // Optional: Link to your CSS file for styling

const SubscribeEmail = () => {
  return (
    <section id="cta-1692">
      <div className="cs-container">
        <div className="cs-content">
          <span className="cs-topper">Stay Connected</span>
          <h2 className="cs-title">
            Subscribe Your Email For News And Updates
          </h2>
          <p className="cs-text">
          Join our mission to build an outpost of Christendom by subscribing for updates and news. Support us further with a donation, becoming a Patron, or partnering long-term. Contact us to learn more.          </p>
          <form className="cs-form" name="Contact Form" method="post">
            <input
              className="cs-input"
              type="email"
              id="cs-email-1692"
              name="find-us"
              placeholder="Enter your email"
            />
            <button className="cs-button-solid cs-submit" type="submit">
              In The Works...
            </button>
          </form>
        </div>
      </div>
      <img
        className="cs-graphic cs-graphic-1"
        src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Graphics/pill-pattern.svg"
        alt="pills"
        height="196"
        width="582"
        loading="lazy"
        decoding="async"
      />
      <img
        className="cs-graphic cs-graphic-2"
        src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Graphics/pill-pattern.svg"
        alt="pills"
        height="196"
        width="582"
        loading="lazy"
        decoding="async"
      />
    </section>
  );
};

export default SubscribeEmail;
