import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import LandingPage from './components/LandingPage';
import Navbar from './components/NavBar';
import OurMission from './components/OurMission';
import DevelopmentPlan from './components/DevelopmentPlan';
import Login from './components/Login';
import ResidentInquiry from './components/ResidentInquiry';
import Donate from './components/Donate';
import Banner from './components/ResidentInquiryPage';
import Footer from './components/Footer';

function App() {

  return (
    <Router>
        <>
          <Navbar /> {/* Show Navbar after login */}
          <Routes>
            <Route path="/" element={<LandingPage />} /> {/* Home page */}
            <Route path="/our-mission" element={<OurMission />} />
            <Route path="/development-plan" element={<DevelopmentPlan />} />
            <Route path="/resident-inquiry" element={<Banner />} />
            <Route path="/donate" element={<Donate />} />
            <Route path="*" element={<LandingPage />} />
            {/* Add other routes here */}
          </Routes>
        </>
        <Footer/>
    </Router>
  );
}

export default App;
