import React from 'react';
import './ResidentInquiryPage.css';
import ResidentInquiry from './ResidentInquiry';

const Banner = () => {
    return (
        <div id="banner-1004">
            <div className="cs-container">
                <span className="cs-int-title">Pascua Questionnaire</span>
                <p className="cs-text">
                If you'd like to be part of Pascua, please complete the questionnaire. We're seeking farmers, tradesmen, business owners, educators, and anyone ready to contribute to a strong, spiritually flourishing, and economically viable community.</p>
                <p className="cs-text">
                 Upload videos via the WormHole App link in the bottom and email the link of your video to us, or opt for a written response.</p>
            </div>
            {/* Background Image */}
            <picture className="cs-background">
                <source 
                    media="(max-width: 600px)" 
                    srcSet="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images%2FMISC%2Ftable.jpg" 
                />
                <source 
                    media="(min-width: 601px)" 
                    srcSet="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images%2FMISC%2Ftable.jpg" 
                />
                <img 
                    decoding="async" 
                    src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images%2FMISC%2Ftable.jpg" 
                    alt="logo" 
                    width="1920" 
                    height="1000" 
                />
            </picture>

            <div>
            <ResidentInquiry/>
            </div>
            
        </div>
    );
};

export default Banner;
