import React from 'react';
import './JoshContent.css'
import josuefamilypicture from'../images/josuefamilypicture.png'

const JoshContent = () => {
    return (
        <section id="content-1638">
            <div className="cs-container">
                <div className="cs-content">
                    <div className="cs-flex">
                        <span className="cs-topper">Founder</span>
                        <h2 className="cs-title">
                        Josué Hernandez
                        </h2>
 
                    </div>
                    <div className="cs-flex">
                        <h3 className="cs-h3">
                        Josué's Background
                        </h3>
                        <p className="cs-text">
                        Josué, the son of Cuban exiles who fled the Communist revolution in the 1960s, was born in New Jersey and raised in Miami, where he gained valuable skills working in his family’s renowned Latin food business, El Sembrador. After the business was sold in 2020, he founded the Pascua Project to address societal challenges. A Catholic convert and husband to Vida, a former educator, Josué enjoys exploring the Church’s traditions, traveling, and hosting friends with his wife.

                        </p>
            
                    </div>
                </div>
            </div>
        </section>
    );
};

export default JoshContent;
