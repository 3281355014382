import React from "react";
import logo from '../images/logo1.svg';
import "./Footer.css"; // Optional: Include this for external styles

const Footer = () => {
  return (
    <footer id="cs-footer-274">
      <div className="cs-container">
        {/* Logo Group */}
        <div className="cs-logo-group">
          <a aria-label="go back to home" className="cs-logo" href="">
            {/* Remove the light class if you don't need the dark logo */}
            <img
              className="cs-logo-img"
              aria-hidden="true"
              loading="lazy"
              decoding="async"
              src={logo}
              alt="logo"
              width="240"
              height="32"
            />
          </a>
          <div className="cs-social">
            <a
              className="cs-social-link"
              aria-label="visit google profile"
              href=""
            >
              <img
                className="cs-social-img"
                aria-hidden="true"
                loading="lazy"
                decoding="async"
                src="https://csimg.nyc3.digitaloceanspaces.com/Social/google.svg"
                alt="google"
                width="11"
                height="11"
              />
            </a>
            <a
              className="cs-social-link"
              aria-label="visit facebook profile"
              href=""
            >
              <img
                className="cs-social-img"
                aria-hidden="true"
                loading="lazy"
                decoding="async"
                src="https://csimg.nyc3.digitaloceanspaces.com/Social/Facebook.svg"
                alt="facebook"
                width="6"
                height="11"
              />
            </a>
            <a
              className="cs-social-link"
              aria-label="visit instagram profile"
              href=""
            >
              <img
                className="cs-social-img"
                aria-hidden="true"
                loading="lazy"
                decoding="async"
                src="https://csimg.nyc3.digitaloceanspaces.com/Social/instagram.svg"
                alt="instagram"
                width="11"
                height="11"
              />
            </a>
          </div>
        </div>

        {/* Links */}
        <ul className="cs-nav">
          <li className="cs-nav-li">
            <span className="cs-header">Contact Us</span>
          </li>
          <li className="cs-nav-li">
            <a className="cs-nav-link" href="tel:123-456-7890">
              (555) 555-0234
            </a>
          </li>
          <li className="cs-nav-li">
            <a className="cs-nav-link" href="mailto:info@codestitch.com">
              pascuaflorida2022@gmail.com
            </a>
          </li>
        </ul>
        <ul className="cs-nav">
          <li className="cs-nav-li">
            <span className="cs-header">Presentation</span>
          </li>
          <li className="cs-nav-li">
            <a className="cs-nav-link" href="https://drive.google.com/file/d/1WdeqlTZJYAHNFIkm-jKFTMswRMFqJ8Wk/view">
              View The
              <br /> Pascua Florida Presentation
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
