import React from 'react';
import './JoshContent.css'
import phillipfamilypicture from'../images/phillipfamily.png'

const PhillipContent = () => {
    return (
        <section id="content-1638">
            <div className="cs-container">
                <div className="cs-content">
                    <div className="cs-flex">
                        <span className="cs-topper">Architect And Town Planner</span>
                        <h2 className="cs-title">
                        Phillip Rhea
                        </h2>

                    </div>
                    <div className="cs-flex">
                        <h3 className="cs-h3">
                        Phillip's Background
                        </h3>
                        <p className="cs-text">
                        Philip, a native Floridian and architect, began his journey with two years in seminary and travels across Europe, where he discovered his love for art and architecture. After earning a Bachelor of Science in Architecture and a Master's from the University of Notre Dame with a focus on Classical Architecture, he dedicated himself to preserving traditional design. Now a licensed architect in Florida, Philip enjoys outdoor activities, music, and sacred choir direction while raising six children with his supportive wife.

                        </p>
                    
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PhillipContent;
