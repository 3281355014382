import React from "react";
import stJohnsRiver from '../images/saint-johns-river.jpg';
import './WhereWeStand.css';

const WhereWeStand = () => {
  return (
    <section id="sbsr-1279">
      <div className="cs-container">
        <picture className="cs-picture">
          {/* Mobile Image */}
          <source
            media="(max-width: 600px)"
            srcSet={stJohnsRiver}
          />
          {/* Tablet and above Image */}
          <source
            media="(min-width: 601px)"
            srcSet={stJohnsRiver}
          />
          <img
            loading="lazy"
            decoding="async"
            src={stJohnsRiver}
            alt="construction"
            width="738"
            height="598"
          />
        </picture>
        <div className="cs-content">
          <div className="cs-flex">
            <h2 className="cs-title">Where We Currently Stand</h2>
          </div>
          <p className="cs-text">
          After extensive research, we have focused on a location in northern Florida near the Saint Johns River, featuring a historic town center surrounded by over 400 acres of land ideal for an agricultural community. This area offers both seclusion and centrality, enabling commerce throughout north and central Florida while fostering a symbiotic relationship with the nearby town, which includes residential and commercial properties for sale. We also envision the potential to incorporate the town into our development plan by retrofitting its existing infrastructure.            
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhereWeStand;
