import React from "react";
import floridaVernacular from '../images/mission-shutterstock.jpg';
import "./DevelopmentPlan.css"; // Assuming you have a CSS file for styling
const DevelopmentPlan = () => {
  return (
    <section id="sbs-1640">
      <div className="cs-container">
        <div className="cs-content">
          <h2 className="cs-title">Our Development Plan</h2>
          <picture className="cs-picture">
            {/* Mobile Image */}
            <source
              media="(max-width: 600px)"
              srcSet={floridaVernacular}
            />
            {/* Tablet and above Image */}
            <source
              media="(min-width: 601px)"
              srcSet={floridaVernacular}
            />
            <img
              loading="lazy"
              decoding="async"
              src={floridaVernacular}
              alt="people"
              width="608"
              height="406"
            />
          </picture>
        </div>
        <div className="cs-stats-group">
          <p className="cs-text">

          The loss of community is one of modern life's greatest challenges, driven by segregated environments where commercial, residential, and recreational spaces are divided by function and income. The New Urbanist Movement seeks to remedy this by applying traditional urban principles to create human-scale, walkable communities, where neighborhoods are integrated and thrive together. Pascua will follow these principles, developing organically with incremental growth that fosters self-sufficient hamlets within an agricultural setting. The community will honor both Spanish and Florida Southern heritage, featuring a mix of Spanish-style and Florida vernacular architecture, while focusing on unity, liberty, and charity. Currently, Pascua is focused on a location near the Saint Johns River, with over 400 acres targeted for development, aiming to blend rural life with nearby towns and commercial activity. </p>
        </div>
      </div>
    </section>
  );
};

export default DevelopmentPlan;
