import React, { useState } from 'react';

function Login({ onLoginSuccess }) {
  const validUsername = 'pascua';
  const validPassword = 'soon';

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginStatus, setLoginStatus] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (username === validUsername && password === validPassword) {
      setLoginStatus('You have entered through the narrow gate.');
      onLoginSuccess(); // Call the function passed from App.js
    } else {
      setLoginStatus('Say three hail marys and try again.');
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div style={{ width: '300px', padding: '20px', border: '1px solid black', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Username:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter username"
              required
              style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              required
              style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
            />
          </div>
          <button type="submit" style={{ width: '100%', padding: '10px', backgroundColor: 'black', color: 'white', cursor: 'pointer' }}>Login</button>
        </form>
        {loginStatus && <p style={{ color: loginStatus.includes('Success') ? 'green' : 'red' }}>{loginStatus}</p>}
      </div>
    </div>
  );
}

export default Login;
