import React from "react";
import './AboutUs.css';

import Josh from '../images/joshheadshot.png';
import PascuaFamilies from '../images/pascuafamilies.png';
import Phillip from '../images/philipheadshot.png';

const AboutUs = () => {
    return (
        <section id="meet-us-348">
            <div className="cs-container">
                <div className="cs-content">
                    <h2 className="cs-title">About Us</h2>
                </div>
                <ul className="cs-card-group">
                    <li className="cs-item">
                        {/* Background Image */}
                        <picture className="cs-background">
                            <source
                                media="(max-width: 767px)"
                                srcSet={Josh}
                            />
                            <source
                                media="(min-width: 768px)"
                                srcSet={Josh}
                            />
                            <img
                                aria-hidden="true"
                                loading="lazy"
                                decoding="async"
                                src={Josh}
                                alt="cleaner"
                                width="305"
                                height="335"
                            />
                        </picture>
                        <div className="cs-info">
                            <span className="cs-name">Josue Hernandez</span>
                            <span className="cs-job">Founder</span>
                        </div>
                    </li>
                    <li className="cs-item">
                        {/* Background Image */}
                        <picture className="cs-background">
                            <source
                                media="(max-width: 767px)"
                                srcSet={Phillip}
                            />
                            <source
                                media="(min-width: 768px)"
                                srcSet={Phillip}
                            />
                            <img
                                aria-hidden="true"
                                loading="lazy"
                                decoding="async"
                                src={Phillip}
                                alt="cleaner"
                                width="305"
                            />
                        </picture>
                        <div className="cs-info">
                            <span className="cs-name">Philip Rhea</span>
                            <span className="cs-job">Architect</span>
                        </div>
                    </li>
                    <li className="cs-item">
                        {/* Background Image */}
                        <picture className="cs-background">
                            <source
                                media="(max-width: 767px)"
                                srcSet={PascuaFamilies}
                            />
                            <source
                                media="(min-width: 768px)"
                                srcSet={PascuaFamilies}
                            />
                            <img
                                aria-hidden="true"
                                loading="lazy"
                                decoding="async"
                                src={PascuaFamilies}
                                alt="cleaner"
                                height="335"
                            />
                        </picture>
                        <div className="cs-info">
                            <span className="cs-name">Pascua Families</span>
                            <span className="cs-job">Contributors</span>
                        </div>
                    </li>
                </ul>

                <div className="cs-bubbles cs-bubbles-small" aria-hidden="true"></div>
                <div className="cs-bubbles cs-bubbles-big" aria-hidden="true">
                    <span className="cs-bubbles-big2"></span>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
