import React from 'react';
import './OurMission.css';

import craftsmen from '../images/craftsman.jpg';
import developmentplan from '../images/developmentplan-12.jpg';
import latinmass from '../images/latinmass.jpg';
import reconquista from '../images/reconquista.png';

const servicesData = [
  {
    title: ' Our Approach',
    text: 'At Pascua, we confront society’s grave challenges not with mere survival in mind but with a vision of human flourishing. Inspired by our Spanish forebears, who turned defeat into victory through resolve and faith, we adopt a strategy of bold, long-sighted action. Guided by this enduring spirit, we strive forward with purpose, resilience, and the courage of reconquest.',
    image: reconquista,
  },
  {
    title: 'Preserving the Flame Of Christendom',
    text: 'The name Pascua is a homage to our land’s Spanish and Christian roots. Pascua Florida, meaning flowery Easter, was the name our Catholic forebears gave our state upon first sighting her shores on Easter Sunday, 1513. We’ve taken this name for our settlement as a reminder of our birthright as heirs of Hispania and of Christendom, and as a sign that as our once dead and defeated God rose from the grave on Easter morning, so too will our vanquished civilization rise forth once more to proclaim Christ King and Mary Queen of its lands.',
    image: latinmass,
  },
  {
    title: 'Culture & Economy',
    text: 'We view the cultivation, processing, cooking, eating, and trading of food as something that will be integral to the life of Pascua. We do not, however, wish to be viewed exclusively and strictly as just an agrarian community. We envision Pascua as a cultural and economic hub that will attract not only agrarians but also tradesmen, craftsmen, entrepreneurs, musicians, writers, the liberally educated, and all sorts of lovers of both high and folk culture. Generally speaking, those with experience and expertise in both the liberal and manual arts. The goal is to form a strong, well-rounded, and economically sustainable community.',
    image: craftsmen,
  },
  {
    title: ' Affordability & Economic Vitality',
    text: 'Providing home and business site affordability and working towards a strong local economy are integral to Pascua’s mission. We therefore aim to make, and keep, Pascua affordable while protecting it from becoming an artificial vacation or rental community or an economically homogeneous community of uniform income levels. We propose to do this by offering various purchase and interest-free financing options for families and entrepreneurs, disincentivizing resale, and incentivizing the creation of small businesses and cottage industries through the Community Land Trust structure.',
    image: developmentplan,
  },
];

const ServiceCard = ({ tag, title, text, image }) => (
  <li className="cs-item">
    <picture className="cs-picture">
      <source media="(max-width: 600px)" srcSet={image} />
      <source media="(min-width: 601px)" srcSet={image} />
      <img
        decoding="async"
        src={image}
        alt={title}
        width="413"
        height="374"
        aria-hidden="true"
        loading="lazy"
      />
    </picture>
    <div className="cs-details">
      <h3 className="cs-h3">{title}</h3>
      <p className="cs-item-text">{text}</p>
    </div>
  </li>
);

const OurMission = () => (
  <section id="services">
    <div className="cs-container">
      <div className="cs-content">
        <div className="cs-flex-group">
          <h2 className="cs-title">Our Mission</h2>
        </div>
        <p className="cs-text">
        The mission of Pascua is to provide a safe haven where Christian culture can take root and thrive. Our aim is to found a settlement modeled on the principles of traditional urban development for the purpose of fostering a thriving and robust Catholic life and culture through the integration of New Urbanism, agrarianism, local trade and ownership of productive property, the liberal arts, historical rootedness, the traditional Roman rite and a hearty and spirited observance of the Church’s liturgical calendar.
        </p>
      </div>
      <ul className="cs-card-group">
        {servicesData.map((service, index) => (
          <ServiceCard
            key={index}
            tag={service.tag}
            title={service.title}
            text={service.text}
            image={service.image}
          />
        ))}
      </ul>
    </div>
  </section>
);

export default OurMission;
